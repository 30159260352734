import Files from "../../components/file/Files"

const FilePage = () => {

    return (

        <Files />
    )

}

export default FilePage