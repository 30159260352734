import EditNote from "../../components/user/EditNote"


const EditNotePage = () => {

    return (
        <EditNote />
    )

}

export default EditNotePage