import CreateNote from "../../components/user/CreateNote"

const CreateNotePage = () => {

    return (
        <CreateNote />
    )

}

export default CreateNotePage