import AllNotes from "../../components/user/AllNotes"


const AllNotesPage = () => {

    return (
        <AllNotes />
    )

}

export default AllNotesPage
