import Login from "../components/auth/Login";

const LoginPage = () => {
    return (
        <Login />
    )
}


export default LoginPage;
