import Dashboard from "../../components/user/Dashboard"

const DashBoardPage = () => {

    return (
        <Dashboard />
    )
}

export default DashBoardPage
